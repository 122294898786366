s-select-group {
  s-select {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;

    .firstEl {
      padding: 0 10px 0 0;
    }

    .lastEl {
      padding: 0 0 0 10px;
    }

    .middleEl {
      padding: 0 10px;
    }
  }
}

.buttonToggleSelect {
  margin: 0 15px 10px;
}

/* s-page-header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
    height: auto;
} */

.mat-mdc-select-panel {
  // Manual Version
  margin-top: 8px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08),
    7px 14px 34px 5px rgba(0, 0, 0, 0.08) !important; //Figma version
  //box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.15) !important; //Manual version
  max-height: 550px !important;
  padding: 12px 0px !important;
  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-left: 0px !important;
  }
}

// .mat-mdc-select-panel {
//     //Figma Version
//     margin-top: 8px !important;
//     display: flex !important;
//     flex-direction:column !important;
//     align-items: flex-start !important;
//     gap: 16px !important;
//     box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08), 7px 14px 34px 0px rgba(0, 0, 0, 0.08) !important; //Figma version
//     //box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.15) !important; //Manual version
//     //max-height: 600px !important;
//     padding: 24px 15px !important;
//       .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
//         padding-left: 0px !important;
//     }
// }

.mat-focused {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08),
    7px 30px 130px 0px rgba(0, 0, 0, 0.08);
}

.mat-mdc-select-value {
  font-size: var(--s-smaller-font-size);
  font-weight: var(--s-bigger-font-weight);
  line-height: normal;
  letter-spacing: -0.36px;
}
.mat-mdc-select-value-text {
  color: var(--s-gray-1);
}

//Scrollbar section - these work in combination
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 6px; //manages horizontal scrollbars
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}
/* Track - this can be removed or used to style the background of the scrollbar*/
::-webkit-scrollbar-track {
  box-shadow: none;
}
