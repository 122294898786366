@use "@angular/material" as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import "./app/shared/components/components.scss";

/* $fontConfig: mat.define-typography-config(
  $display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  $display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  $display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  $display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  $headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  $title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  $subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  $subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  $body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  $button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  $caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  $input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
); */

$fontConfig: mat.define-legacy-typography-config();

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

@include mat.core();

// Theme Config

body {
  --primary-color: #243882;
  --primary-lighter-color: #bdc3da;
  --primary-darker-color: #162465;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #243882,
  lighter: #bdc3da,
  darker: #162465,
  200: #243882,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #eff0f4;
  --accent-lighter-color: #fafbfc;
  --accent-darker-color: #e8e9ef;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #eff0f4,
  lighter: #fafbfc,
  darker: #e8e9ef,
  200: #eff0f4,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #eb5757;
  --warn-lighter-color: #f9cdcd;
  --warn-darker-color: #e23c3c;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #eb5757,
  lighter: #f9cdcd,
  darker: #e23c3c,
  200: #eb5757,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  )
);

$altTheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  )
);

//Other Sytles

:root {
  //Colors
  --s-orange: #ffa500;
  --s-red: #EB5757;
  --s-green-1: #219653;
  --s-gray-1: #333;
  --s-gray-2: #4f4f4f;
  --s-dark-gray: #565656;
  --s-light-gray: #8f8f8f;
  --s-gray-5: #e0e0e0;
  --s-gray-select-borders: #f1f0f0;

  //Font-Families
  --s-primary-font-family: "Encode Sans", "EncodeSansRegular", sans-serif;
  --s-secondary-font-family: "Open Sans", sans-serif;

  //Sizes
  --s-border-size: 0.5px;

  --s-xs-font-weight: 300;
  --s-smaller-font-weight: 400;
  --s-medium-font-weight: 500;
  --s-bigger-font-weight: 600;
  --s-extra-font-weight: 700;

  --s-smaller-font-size: 12px;
  --s-medium-font-size: 14px;
  --s-bigger-font-size: 16px;
  --s-extra-font-size: 20px;
}

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Roboto";
  }
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field-infix {
  min-height: 45px;
  width: min-content !important;
  padding: 0px !important;
  font-size: var(--s-smaller-font-size);
  display: flex;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  font-size: var(--s-smaller-font-size) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #edeef3;
}

.mdc-text-field--outlined .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline__trailing {
  border-radius: 0 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):hover {
  border-color: var(--primary-color);
}

body {
  font-family: var(--s-primary-font-family);
}

.loginLogo {
  margin-top: 48px;
  html,
  body {
    height: 100% !important;
    min-width: 1024px !important;
  }
  body {
    margin: 0;
    font-family: var(--s-primary-font-family), Roboto, "Helvetica Neue", sans-serif;
  }
}

app-review-page,
app-exception-page {
  width: 100%;
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mdc-fab__ripple {
  border-radius: 0 !important;
}

//Buttons styles
.white-background {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color: white !important;
    color: var(--primary-color) !important;
    border: 2px solid var(--primary-color) !important;
  }
}
.primary-background {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color: var(--primary-color) !important;
    color: white !important;
    border: 2px solid var(--primary-color) !important;
  }
}
.orange-background {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color: var(--s-orange) !important;
    color: white !important;
    border: 2px solid var(--s-orange) !important;
  }
}
.green-background {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color: var(--s-green-1) !important;
    color: white !important;
    border: 2px solid var(--s-green-1) !important;
  }
}
.red-background {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color: var(--warn-color) !important;
    color: white !important;
    border: 2px solid var(--warn-color) !important;
  }
}
.green-outline {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color:white !important;
    color:  var(--s-green-1) !important;
    border: 2px solid var(--s-green-1) !important;
  }
  .mat-icon {
    color: white;
    background:  var(--s-green-1);
    border-radius: 20px;
    transform: scale(0.6) !important;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.red-outline {
  .mdc-button,
  .mat-mdc-fab.mat-primary {
    background-color: white !important;
    color: var(--warn-color) !important;
    border: 2px solid var(--warn-color) !important;
  }
}

.snackbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 8999;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: 2.2rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
  font-weight: var(--s-extra-font-weight);
  margin: 0;
  font-size: var(--s-bigger-font-size);
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    border-bottom: 0.5px solid var(--s-gray-select-borders);
  }
}
.mat-pseudo-checkbox-full {
  border: 0 !important;
  margin-right: 0 !important;
  margin-left: 16px !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: transparent;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after {
  color: var(--primary-color);
}

.mdc-list-item__primary-text {
  //border-bottom: 0.601559px solid var(--s-gray-select-borders); //uncomment to have the options in the lateral menu underlined
  width: 100%;
  margin-right: 34px !important;
}

.mdc-list-item--selected {
  .mdc-list-item__primary-text {
    margin-right: 0px !important;
    font-weight: var(--s-medium-font-weight) !important;
  }
}

.filter {
  width: 100%;
  border: 0;
  height: 56px;
  display: block;
  padding: 0 15px; //Figma Comment

  mat-icon {
    color: var(--primary-color) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .mat-mdc-icon-button {
    padding: 0 !important;
    width: 30px;
    height: 30px;
  }
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
  .mat-ripple {
    display: none;
  }
}

.mat-mdc-option {
  font-size: var(--s-smaller-font-size) !important;
  min-height: 30px !important;
  //border-bottom: 0.601559px solid var(--s-gray-select-borders);
  padding: 0 !important;
  margin: 0 15px;
}

.mat-mdc-optgroup {
  .mat-mdc-optgroup-label {
    margin: 16px 0;
    padding: 0 15px;
    min-height: 30px !important;

    .mdc-list-item__primary-text {
      color: var(--primary-color);
      font-weight: var(--s-bigger-font-weight);
      font-size: var(--s-medium-font-size);
      border: 0;
    }
  }
  .mat-mdc-option {
    border-left: var(--s-border-size) solid var(--s-gray-select-borders);
    padding: 0 6px;
  }
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: transparent;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0px !important;
  line-height: 34px;
  font-size: var(--s-smaller-font-size);
  font-style: normal;
  font-weight: var(--s-bigger-font-weight);
  line-height: normal;
}

.mat-grid-tile-content > * {
  width: 100%;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

//border-bottom search field
.mdc-line-ripple--deactivating::before
{
  border-bottom: 0.5px solid var(--s-gray-select-borders) !important;
}
